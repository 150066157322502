import dayjs from 'dayjs';
import uk from 'dayjs/locale/uk';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('uk')
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

export function formattedDateTime (date) {
    return dayjs(date).format('LLL')
}

export function formattedDate (date) {
    return dayjs(date).format('LL')
}

export function year (date) {
    return dayjs(date).year()
}

export function date () {
    return dayjs()
}

export function fromNow (date) {
    return dayjs(date).fromNow()
}
